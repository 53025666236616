function managePositions(jsonData, originalParentId, entityHasId, newElementLabel, entityPosition) {
  var entitiesCount = $.parseJSON(jsonData);
  var positionParents = $('.position-parent');
  $('.position-parent').on('change', function () {
    $('#position').find('option').remove();
    if (entitiesCount[$('.position-parent').val()] != undefined) {
      for (var i = 1; i <= entitiesCount[$('.position-parent').val()].length; i++) {
        $('#position').append($('<option>').val(i).text(i + ' - ' + entitiesCount[$('.position-parent').val()][i - 1]));
      }
    } else {
      var i = 1;
    }
    if (!entityHasId || $('.position-parent').val() != originalParentId) {
      $('#position').append($('<option>').val(i).text(i + ' - ' + newElementLabel));
    }
    if (entityHasId) {
      $('#position option[value="' + entityPosition + '"]').attr('selected', 'selected');
    } else {
      $('#position option:last-child').attr('selected', 'selected');
    }
  });
  $('.position-parent').change();
}

$(function () {

  // TinyMCE Editor
  function elFinderBrowser(field_name, url, type, win) {
    tinymce.activeEditor.windowManager.open({
      file: '/admin/elfinder/tinymce4', // use an absolute path!
      title: 'File manager',
      width: 900,
      height: 450,
      resizable: 'yes'
    }, {
      setUrl: function (url) {
        win.document.getElementById(field_name).value = url;
      }
    });
    return false;
  }

  if (typeof(tinymce) !== 'undefined') {
    tinymce.init({
      selector: 'textarea.tinymce',
      content_css: '/css/tinymce/tinymce_content.css',
      document_base_url: 'http://' + window.location.hostname,
      convert_urls: false,
      relative_urls: false,
      remove_script_host: false,
      plugins: [
        'image pagebreak wordcount code nonbreaking save table link',
        'insertdatetime textcolor colorpicker textpattern visualblocks',
        'searchreplace visualchars',
        'anchor',
      ],
      toolbar1: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | forecolor backcolor | bullist numlist outdent indent | link anchor image media table",
      height: 300,
      file_browser_callback: elFinderBrowser,
    });

    // Gallery uploader
    $('#gallery-upload').on('click', function () {
      $('.gallery-uploader-container').fadeToggle();
    });
  }

  // jQuery File Uploader
  'use strict';

  //Inputs decoration
  $('input.minimal').iCheck({
    checkboxClass: 'icheckbox_square-blue',
    radioClass: 'iradio_square-blue',
    increaseArea: '20%' // optional
  });

  // Selectize
  $('.selectize').selectize({
    sortField: 'text'
  });
});